import { useSpring, animated } from 'react-spring'
import ReactDOM from 'react-dom'
import ClearIcon from '../layout/icons/actions/ClearIcon'
import Backdrop from '../layout/tools/Backdrop'

function Modal({ isModalVisible, onBackdropClick, content, fullWidth, translateStart, translateEnd }) {

    const animation = useSpring({           
      opacity:    isModalVisible ? 1 : 0,
      transform:  isModalVisible 
        ? translateStart 
          ? translateStart
          : 'translateX(0)' 
        : translateEnd 
          ? translateEnd
          : 'translateX(100%)'
    })    

    if(!isModalVisible)  return null

    return ReactDOM.createPortal( 
      <>
      <Backdrop onclick={onBackdropClick} />

      <animated.div className={` ${fullWidth && 'fullWidth'} modal`} style={animation}>  

        <div className="screenheight"> 

          {content}
          
        </div> 

        <span style={{ position: 'absolute', top: '5px', right: '5px' }}>
            <button 
              className="icon-button"
              aria-label="Close"
              onClick={onBackdropClick} 
            >
                <ClearIcon />
            </button>
        </span> 
        
      </animated.div>
      </>,
      document.getElementById('modal-root')    
    )
}

export default Modal
