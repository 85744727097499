import { lazy, Suspense, useEffect, useState } from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'
import { PayPalScriptProvider } from '@paypal/react-paypal-js'
import { loadUser } from './actions/userActions'
import { useTransition, animated } from 'react-spring'
import { lazyRetry } from './utils'
import store from './store'
import axios from 'axios'
// Payment imports 
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
// Layout import
import Header from './components/layout/header/Header'
import Footer from './components/layout/footer/Footer'
import PrivateRoute from './components/route/PrivateRoute'
import ScrollToTop from './components/route/ScrollToTop'
import Loader from './components/layout/tools/Loader'
// Page import 
import Home from './components/Home'
import ProductDetails from './components/products/ProductDetails'
import Error404 from './components/Error404'

const Terms          = lazy(() => lazyRetry(() => import('./components/Terms')))
const Privacy        = lazy(() => lazyRetry(() => import('./components/Privacy')))
const Faqs           = lazy(() => lazyRetry(() => import('./components/Faqs')))

const Login          = lazy(() => lazyRetry(() => import('./components/user/Login')))
const Register       = lazy(() => lazyRetry(() => import('./components/user/Register')))
const UpdateProfile  = lazy(() => lazyRetry(() => import('./components/user/UpdateProfile')))
const UpdatePassword = lazy(() => lazyRetry(() => import('./components/user/UpdatePassword')))
const ForgotPassword = lazy(() => lazyRetry(() => import('./components/user/ForgotPassword')))
const NewPassword    = lazy(() => lazyRetry(() => import('./components/user/NewPassword')))

const ListOrders     = lazy(() => lazyRetry(() => import('./components/order/ListOrders')))
const OrderDetails   = lazy(() => lazyRetry(() => import('./components/order/OrderDetails')))

const Dashboard      = lazy(() => lazyRetry(() => import('./components/admin/Dashboard')))

const UsersList      = lazy(() => lazyRetry(() => import('./components/admin/users/UsersList')))
const UpdateUser     = lazy(() => lazyRetry(() => import('./components/admin/users/UpdateUser')))

const ProductList    = lazy(() => lazyRetry(() => import('./components/admin/products/ProductList')))
const NewProduct     = lazy(() => lazyRetry(() => import('./components/admin/products/NewProduct')))
const UpdateProduct  = lazy(() => lazyRetry(() => import('./components/admin/products/UpdateProduct')))

const ProductReviews = lazy(() => lazyRetry(() => import('./components/admin/products/ProductReviews')))

const OrdersList     = lazy(() => lazyRetry(() => import('./components/admin/orders/OrdersList')))
const ProcessOrder   = lazy(() => lazyRetry(() => import('./components/admin/orders/ProcessOrder')))

const stripePromise = process.env.REACT_APP_NODE_ENV === 'PRODUCTION'
  ? loadStripe(process.env.REACT_APP_STRIPE_API_KEY)
  : loadStripe(process.env.REACT_APP_STRIPE_API_KEY_DEV)

const App = () => {     

  const location = useLocation()

  const [ stripeApiKey, setStripeApiKey ] = useState('')
  
  useEffect(() => {    

    store.dispatch(loadUser())   

    async function getStripApiKey() {
      const { data } = await axios.get('/api/v1/stripeapi')
      setStripeApiKey(data.stripeApiKey)
    }

    getStripApiKey()
    
  }, [])    

  const transitions = useTransition( location, {
    from:  { opacity: 0,   position: 'relative', left: '100%' },
    enter: { opacity: 1,   position: 'relative', left: '0%' },
    leave: { opacity: 0.5, position: 'absolute', width: '100%', top: 64, left: '-100%' }
  }) 

  const initialOptions = {
      clientId: process.env.REACT_APP_NODE_ENV === 'PRODUCTION' 
        ? process.env.REACT_APP_PAYPAL_CLIENT_ID
        : process.env.REACT_APP_PAYPAL_CLIENT_ID_SANDBOX,
      currency: process.env.REACT_APP_CURRENCY_CODE,
      intent: "capture",
      locale: "en_US"
  }

  return (
    <>
    <PayPalScriptProvider options={initialOptions}>

    {stripeApiKey && 
      
      <Elements stripe={stripePromise}>
    
        <Header />

        {transitions((props, item) => (  

          <animated.main style={props}>

            <ScrollToTop />      

            <Suspense fallback={<Loader />}>

              <Routes location={item}>

                <Route path="/" element={<Home />} /> 
                <Route path="/product/:slug" element={<ProductDetails />} />                

                <Route path="/terms" element={<Terms />} />
                <Route path="/privacy" element={<Privacy />} />
                <Route path="/faqs" element={<Faqs />} />                

                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route path="/password/forgot" element={<ForgotPassword />} />
                <Route path="/password/reset/:token" element={<NewPassword />} /> 
                <Route path="/me/update" element={<PrivateRoute><UpdateProfile /></PrivateRoute>} />
                <Route path="/password/update" element={<PrivateRoute><UpdatePassword /></PrivateRoute>} />

                <Route path="/orders/me" element={<PrivateRoute><ListOrders /></PrivateRoute>} />
                <Route path="/order/:id" element={<PrivateRoute><OrderDetails /></PrivateRoute>} />

                <Route path="/admin/dashboard" element={<PrivateRoute isAdmin={true}><Dashboard /></PrivateRoute>} />
                
                <Route path="/admin/users" element={<PrivateRoute isAdmin={true}><UsersList /></PrivateRoute>} />
                <Route path="/admin/user/:id" element={<PrivateRoute isAdmin={true}><UpdateUser /></PrivateRoute>} />

                <Route path="/admin/products" element={<PrivateRoute isAdmin={true}><ProductList /></PrivateRoute>} />                
                <Route path="/admin/product" element={<PrivateRoute isAdmin={true}><NewProduct /></PrivateRoute>} />
                <Route path="/admin/product/:id" element={<PrivateRoute isAdmin={true}><UpdateProduct /></PrivateRoute>} />        
                <Route path="/admin/reviews" element={<PrivateRoute isAdmin={true}><ProductReviews /></PrivateRoute>} />

                <Route path="/admin/orders" element={<PrivateRoute isAdmin={true}><OrdersList /></PrivateRoute>} />
                <Route path="/admin/order/:id" element={<PrivateRoute isAdmin={true}><ProcessOrder /></PrivateRoute>} />

                <Route path="*" element={<Error404 />} />
                
              </Routes>

            </Suspense>        

          </animated.main>

        ))} 

        <Footer />

      </Elements>

    }

    </PayPalScriptProvider>
    </>
  )
}

export default App
