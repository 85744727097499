import { useState } from "react"
import Modal from "../../modals/Modal"
import CurrencyConverter from "../../modals/CurrencyConverter"
import CurrencyExchangeIcon from "../icons/navigation/CurrencyExchangeIcon"
import ToolTip from "./ToolTip"

const OpenCurrencyExchange = ({ value }) => {

    const [ isModalOpen, setIsModalOpen ] = useState(false)  

    const handleModal = () => {
        setIsModalOpen(!isModalOpen)
    }

    return (
        <>
        <ToolTip title="Currency exchange">
            <button onClick={handleModal} aria-label="currency converter">
                <CurrencyExchangeIcon style={{fontSize: '1rem'}} />
            </button>
        </ToolTip>

        <Modal
            isModalVisible={isModalOpen} 
            onBackdropClick={handleModal}   
            content={ 
                <CurrencyConverter value={value} />  
            }
        />
        </>
    )

}

export default OpenCurrencyExchange