import axios from 'axios'

export const checkUrl = (value) => {        
    let urlRegex = /^https?:\/\//    
    return urlRegex.test(value) ? true : false
}

const calculatePositions = (path, value) => {      
    return new Promise(
        async (resolve) => { // pages
            const data = await axios.get( `/api/v1/${path}?category=${value}` )
            resolve( { data } ) 
        }
    )        
}

export const handlePositions = (path, value, setPositions, mode) => {

    calculatePositions(path, value)
        .then(
            function(result) {

                const data = result.data.data.products
                                          
                const array = []  

                const length = mode === 'create' ? data.length + 1 : data.length      
                
                for (let i = 1; i <= length; i++) {
                    array.push(
                        {
                            label: i,
                            value: i
                        }
                    )
                }
                return setPositions(array)    
            },
            function(error) {console.log(error)}
        )
}

export const sanitizeInput = (value) => {
    value = value.replace(/[^\w -]/ig, '')
    value = value.replace(/ /ig, '-')
    return value.toLowerCase()  
}

export const urlencodeFormData = ( formData ) => {
    const params = new URLSearchParams()
    for( let pair of formData.entries() ) {
        typeof pair[1]=='string' && params.append( pair[0], pair[1] )
    }
    return params.toString()
}  

export const setImageArray = (e, setImagesPreview, setImages) => {        
    const files = Array.from(e.target.files)
    setImagesPreview([])
    setImages([])
    files.forEach(file => {
        const reader = new FileReader()
        reader.onload = () => {
            if(reader.readyState === 2) {
                setImagesPreview(oldArray => [...oldArray, reader.result])
                setImages(oldArray => [...oldArray, reader.result])
            }
        }
        return reader.readAsDataURL(file)
    })   
} 

export const setMainImage = (e, setFeaturedImagePreview, setFeaturedImage) => {
    const reader = new FileReader()
    reader.onload = () => {
        if(reader.readyState === 2) {
            setFeaturedImagePreview(reader.result)
            setFeaturedImage(reader.result)
        }
    }
    return reader.readAsDataURL(e.target.files[0])        
} 

export const formatDate = (date) => {
    const d     = new Date(date)
    const day   = d.getDate() < 10 ? `0${d.getDate()}` : d.getDate()
    const month = d.getMonth() + 1 < 10 ? `0${d.getMonth() + 1}` : d.getMonth() + 1
    const year  = d.getFullYear() 
    return new Date(`${year}-${month}-${day}`).getTime()
}

export const formatMyDate = (dateStr) => {
    const d = new Date(dateStr)
    const day     = d.getDate() < 10 ? `0${d.getDate()}` : d.getDate()
    const month   = d.getMonth() + 1 < 10 ? `0${d.getMonth() + 1}` : d.getMonth() + 1
    const year    = d.getFullYear()
    return `${day}/ ${month}/ ${year}`
}

export const formatMyDateTime = (dateStr) => {

    const d = new Date(dateStr)

    const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
    }
    
    const formattedDate = d.toLocaleDateString('en-GB', options) // 8 June 2023 at 23:53
    // const formattedTime = d.toLocaleTimeString('en-US', options) // June 8, 2023 at 11:51 PM
    
    return formattedDate
}

export const formatPrice = (x, s) => {
    if (!x && x !== 0) return
    let y
    y = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") 
    y += ` ${s ? s : process.env.REACT_APP_CURRENCY_SYMBOL}`
    return y
} 

export const scrollAnimation = () => {
    const appearOptions = {
        threshold: 0,
        routeMargin: '0px 0px -250px 0px'
    }   
    const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                entry.target.classList.add('appear')
                // observer.unobserve(entry.target) // reset
            } else {
                entry.target.classList.remove('appear')
            }
        })
    }, appearOptions)

    const faders = document.querySelectorAll('.fade-in')
    faders.forEach((el) => observer.observe(el))
}

export const cloudinaryURL = (url) => {
    return url.replace('/upload/', '/upload/f_auto/').replace('.jpg', '');
}

export const lazyRetry = (componentImport) => {
    return new Promise((resolve, reject) => {
        const hasRefreshed = JSON.parse(
            window.sessionStorage.getItem('retry-lazy-refreshed') || 'false'
        )
        componentImport().then((component) => {
            window.sessionStorage.setItem('retry-lazy-refreshed', 'false') 
            resolve(component)
        }).catch((error) => {
            if (!hasRefreshed) { 
                window.sessionStorage.setItem('retry-lazy-refreshed', 'true') 
                return window.location.reload() 
            }
            reject(error) 
        })
    })
  }
