import { useState, useEffect, useRef } from 'react'
import { cloudinaryURL } from '../../../utils'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'
import Modal from '../../modals/Modal'
import Lightbox from '../lightbox/Lightbox'
import ArrowBackIcon from '../../layout/icons/arrows/ArrowBackIcon'
import ArrowForwardIcon from '../../layout/icons/arrows/ArrowForwardIcon'
import './thumbnails.css'

const Thumbnails = ({ data, currentIndex, setCurrentIndex }) => {

  const [ isLightboxOpen, setIsLightboxOpen ] = useState(false)
  const [ isControlsOpen, setIsControlsOpen ] = useState(false)

  const progressBarRef = useRef(null)
  const sliderRef      = useRef(null)

  useEffect(() => {
    
    const calculateProgressBar = () => {
      const slider = sliderRef.current
      const progressBar = progressBarRef.current
      if (!slider || !progressBar) return

      const itemCount = slider.children.length

      const itemsPerScreen = parseInt(
        getComputedStyle(slider).getPropertyValue('--items-per-screen')
      )
      let sliderIndex = parseInt(
        getComputedStyle(slider).getPropertyValue('--slider-index')
      )

      setIsControlsOpen(itemCount > itemsPerScreen)

      const progressBarItemCount = Math.ceil(itemCount / itemsPerScreen)

      if (sliderIndex >= progressBarItemCount) {
        slider.style.setProperty('--slider-index', progressBarItemCount - 1)
        sliderIndex = progressBarItemCount - 1
      }

      progressBar.innerHTML = ''

      for (let i = 0; i < progressBarItemCount; i++) {
        const barItem = document.createElement('li')
        if (i === sliderIndex) {
          barItem.classList.add('active')
        }
        progressBar.appendChild(barItem)
      }
    }

    calculateProgressBar()

    window.addEventListener('resize', calculateProgressBar)

    return () => {
      window.removeEventListener('resize', calculateProgressBar)
    }
  }, [])

  const moveSlide = (dir, handle) => {
    const progressBar = progressBarRef.current
    const slider = sliderRef.current
    const sliderIndex = parseInt(
      getComputedStyle(slider).getPropertyValue('--slider-index')
    )
    const progressBarItemCount = progressBar.children.length

    if (dir === 'left') {
      if (sliderIndex - 1 < 0) {
        slider.style.setProperty('--slider-index', progressBarItemCount - 1)
        progressBar.children[sliderIndex].classList.remove('active')
        progressBar.children[progressBarItemCount - 1].classList.add('active')
      } else {
        slider.style.setProperty('--slider-index', sliderIndex - 1)
        progressBar.children[sliderIndex].classList.remove('active')
        progressBar.children[sliderIndex - 1].classList.add('active')
      }
    } else if (dir === 'right') {
      if (sliderIndex + 1 >= progressBarItemCount) {
        slider.style.setProperty('--slider-index', 0)
        progressBar.children[sliderIndex].classList.remove('active')
        progressBar.children[0].classList.add('active')
      } else {
        slider.style.setProperty('--slider-index', sliderIndex + 1)
        progressBar.children[sliderIndex].classList.remove('active')
        progressBar.children[sliderIndex + 1].classList.add('active')
      }
    }
  }

  const handleLightbox = () => {
    setIsLightboxOpen(!isLightboxOpen)
  }

  const handleClick = (i) => {
    setCurrentIndex(i)
    handleLightbox()
  }

  return (
    <div className="thumbnails-body">
      <ul
        ref={progressBarRef}
        className="progress-bar"
        style={{ opacity: isControlsOpen ? 1 : 0 }}
      ></ul>

      <div className="relative">
        <div className="thumbnails-holder">
          <div ref={sliderRef} className="thumbnails-slider">
            {data.images &&
              data.images.map((image, i) => (
                <div key={i}>
                  <figure                  
                      className={i === currentIndex ? 'active' : ''}
                      onMouseEnter={() => setCurrentIndex(i)}
                  >         
                      <LazyLoadImage
                          src={cloudinaryURL(image.thumbUrl)}
                          alt={data.title || data.name}
                          effect="blur"
                          placeholderSrc={cloudinaryURL(image.thumbUrl)}
                          className="object-fit"
                          style={{ objectFit: 'contain' }}   
                          onClick={() => handleClick(i)} 
                      />
                  </figure>
                </div>
              ))}
          </div>
        </div>

        {isControlsOpen && (
          <div className="thumbnails-controls">
            <button
              className="icon-button"
              aria-label="back"
              onClick={(e) => moveSlide('left', e.target)}
              style={{ background: 'rgba(255,255,255,0.8)', left: '-25px' }}
            >
              <ArrowBackIcon />
            </button>

            <button
              className="icon-button"
              aria-label="next"
              onClick={(e) => moveSlide('right', e.target)}
              style={{ background: 'rgba(255,255,255,0.8)', right: '-25px' }}
            >
              <ArrowForwardIcon />
            </button>
          </div>
        )}
      </div>

      <Modal
        isModalVisible={isLightboxOpen}
        onBackdropClick={handleLightbox}
        fullWidth={true}
        translateStart="translateY(0)"
        translateEnd="translateY(100%)"
        content={
          <Lightbox
            images={data.images}
            isLightboxOpen={isLightboxOpen}
            setIsLightboxOpen={setIsLightboxOpen}
            currentIndex={currentIndex}
            setCurrentIndex={setCurrentIndex}
            altTag={data.title || data.name}
          />
        }
      />
    </div>
  )
}

export default Thumbnails
