import { useState, useEffect } from 'react'
import { useAlert } from 'react-alert'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation, useParams } from 'react-router-dom'
import { getProductDetails, deleteReview, getProducts } from '../../actions/productActions'
import { NEW_REVIEW_RESET, DELETE_REVIEW_RESET } from '../../constants/productConstants'
import { clearErrors } from '../../actions/userActions'
import { cloudinaryURL, formatPrice } from '../../utils'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import Loader from '../layout/tools/Loader'
import MetaData from '../layout/MetaData'
import Modal from '../modals/Modal'
import Review from '../modals/Review'
import ListReviews from '../review/ListReviews'
import Thumbnails from '../images/thumbnails/Thumbnails'
import StructuredData from './StructuredData'
import RichtextOutput from '../layout/richtext/RichtextOutput'
import Product from './Product'
import EditOutlinedIcon from '../layout/icons/actions/EditOutlinedIcon'
import LoginIcon from '../layout/icons/login/LoginIcon'
import AddIcon from '../layout/icons/actions/AddIcon'
import RemoveIcon from '../layout/icons/actions/RemoveIcon'
import Rating from '../layout/tools/Rating'
import Parallax from '../images/Parallax'
import BuyNow from '../layout/tools/BuyNow'
import Sidebar from '../Sidebar'
import OpenCurrencyExchange from '../layout/tools/OpenCurrencyExchange'
import ToolTip from '../layout/tools/ToolTip'
import Lightbox from '../images/lightbox/Lightbox'
import 'react-lazy-load-image-component/src/effects/blur.css'

const ProductDetails = () => { 

    const slug     = useParams().slug  
    const alert    = useAlert()
    const dispatch = useDispatch()

    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)

    const { error: reviewError, success     } = useSelector( state => state.newReview )
    const { loading, product, error         } = useSelector( state => state.productDetails )
    const { user                            } = useSelector( state => state.auth )  
    const { isDeleted, error: deleteError   } = useSelector( state => state.review ) 
    const { products, filteredProductsCount } = useSelector( state => state.products )

    const [ quantity,       setQuantity       ] = useState(1)
    const [ isModalVisible, setIsModalVisible ] = useState(false)
    const [ currentIndex,   setCurrentIndex   ] = useState(0)
    const [ isLightboxOpen, setIsLightboxOpen ] = useState(false)

    let rating = 0
    let comment = ''  

    if ( user && product && product.numOfReviews > 0 ) {
        for (let i = 0; i < product.numOfReviews; i++) {
            if ( product.reviews[i].user === user._id ) {                    
                rating = product.reviews[i].rating
                comment = product.reviews[i].comment
            }
        }
    } 

    const increaseQty = () => {        
        if(quantity >= product.stock) { return }
        setQuantity(quantity + 1)
    }
    const decreaseQty = () => {
        if(quantity <= 1) { return }
        setQuantity(quantity - 1)
    }  

    const toggleModal = () => {
        setIsModalVisible(!isModalVisible)  
    }  

    const deleteReviewHandler = (id) => {
        dispatch(deleteReview(id, product._id))        
    }  

    const handleLightbox = () => {
        setIsLightboxOpen(!isLightboxOpen)
    }

    const handleClick = (i) => {
        setCurrentIndex(i)
        handleLightbox()
    }

    useEffect( () => {  

        dispatch(getProducts(1, product.category, [0, 1000], 0, '', product._id)) 
         
        if(reviewError) { 
            alert.error(reviewError)
            dispatch(clearErrors())
         } 
         if(success) {
            alert.success('Review posted')
            dispatch({ type: NEW_REVIEW_RESET })
            dispatch(getProductDetails(product.slug))
            setIsModalVisible(false)
        } 
        if (deleteError) {
            alert.error(deleteError)
            dispatch(clearErrors())
        }    
        if(isDeleted) {
            alert.success('Review deleted')    
            dispatch(getProductDetails(product.slug))        
            dispatch({ type: DELETE_REVIEW_RESET })
        } 
    }, [dispatch, product, success, alert, reviewError, deleteError, isDeleted, product.category, product._id])

    useEffect(() => {

        dispatch(getProductDetails(slug))
        
        if(error) {    
            dispatch(clearErrors())            
            return alert.error(error)                    
        } 
    }, [dispatch, alert, error, slug])      
      
    return (
        <>  
        {loading ? <Loader position="absolute" /> : (
            <>
            <MetaData title={product.name} description={product.description} />

            {product.images && product.images[0] && (
                <StructuredData
                    title={product.name}
                    description={product.description}
                    thumb={product.images[0].thumbUrl}
                    image={product.images[0].url}
                    date={product.datePublished}
                    category={product.category}
                    rating={product.ratings}
                    numOfReviews={product.numOfReviews}
                />
            )}  

            <div style={{ background: 'var(--card-background)', borderBottom: 'solid 1px var(--border-color)' }}>            
            
                <div className="container contained">      

                    <div className="parent">

                        <aside className="background-panel wrapper">

                            <Sidebar />

                        </aside>

                        <article className="main-content wrapper" style={{ background: 'var(--background)', minHeight: 'calc(100vh - 64px)' }}>

                            <div className="parent gap-4">                           

                                {product.images && (
                                    <div className="d-flex direction-column justify-content-between" style={{ flex: 1 }}>
                                        
                                        <h1>{product.name}</h1>  
                                        
                                        <figure className="text-center" style={{ height: 350 }}>
                                            
                                            <LazyLoadImage
                                                src={product.images[currentIndex] ? cloudinaryURL(product.images[currentIndex].url) : ''} 
                                                alt={product.name} 
                                                effect="blur"
                                                placeholderSrc={product.images[currentIndex] ? cloudinaryURL(product.images[currentIndex].thumbUrl) : ''}
                                                className="object-fit"
                                                style={{ objectFit: 'contain', cursor: 'zoom-in' }}    
                                                onClick={() => handleClick(currentIndex)}
                                            />

                                        </figure>  
                                        
                                        <Thumbnails 
                                            data={product} 
                                            currentIndex={currentIndex}
                                            setCurrentIndex={setCurrentIndex}
                                        />
                                       
                                    </div>
                                )} 

                                <div className="d-flex direction-column justify-content-center">                        

                                    <table className="cart-table">  
                                        <tbody> 
                                            {product.price > 0 && (
                                                <tr>                               
                                                    <th style={{ color: 'red' }}><b>SPECIAL<br />OFFER</b></th>    
                                                    <td 
                                                        className="text-center"
                                                        style={{ fontSize: '1rem', textTransform: 'uppercase', color: 'white', background: 'red' }}
                                                    >
                                                        <b style={{ fontSize: '1.5rem' }}>50% off!</b>  
                                                        <br />
                                                        <b>10 or more</b>
                                                    </td>
                                                </tr>     
                                            )}                                                     
                                            <tr>
                                                <th>Category</th>
                                                <td>{product.category}</td>
                                            </tr>
                                            <tr>
                                                <th>Dimensions</th>
                                                {product.width && product.height ? (                                        
                                                    <td>{product.width} cm <small>x</small> {product.height} cm</td>
                                                ) : <td>By arrangement</td>}  
                                            </tr>                                
                                            <tr>                               
                                                <th>Price</th>    
                                                <td>
                                                    {product.price ? formatPrice(product.price, 'Thai baht') : 'N/A'}  
                                                    &nbsp; 
                                                    &nbsp;
                                                    <OpenCurrencyExchange value={product.price ? product.price : 1} />  
                                                </td>
                                            </tr>                            
                                            <tr>
                                                <th>Rating</th>    
                                                <td className="whitespace-nowrap">  
                                                    <span style={{ position: 'relative', top: 8, pointerEvents: 'none' }}>                                      
                                                        <Rating ratings={product.ratings} />
                                                        <small>({product.numOfReviews})</small>
                                                    </span>
                                                </td>
                                            </tr>                                
                                            <tr>
                                                <th>Stock</th>
                                                <td>{product.stock}</td>
                                            </tr>
                                            {product.stock > 0 && (                                           
                                                <tr>
                                                    <th>Quantity</th>                                    
                                                    <td>
                                                        <div className="d-flex">  
                                                            <ToolTip title="Remove">
                                                                <button 
                                                                    className={quantity <= 1 
                                                                        ? 'icon-button pointer-events-none' 
                                                                        : 'icon-button'
                                                                    } 
                                                                    aria-label="remove"
                                                                    onClick={decreaseQty}
                                                                >
                                                                    <RemoveIcon className={quantity <= 1 ? 'danger ' : ''} 
                                                                    />
                                                                </button>
                                                            </ToolTip>

                                                            <input
                                                                className="text-center"
                                                                value={product.stock === 0 ? 0 : quantity} 
                                                                readOnly 
                                                                style={{ color: 'var(--icon-color)', width: '40px' }}
                                                                aria-label="quantity"
                                                            />  

                                                            <ToolTip title="Add"> 
                                                                <button 
                                                                    className={quantity === product.stock || product.stock <= 1 
                                                                        ? 'icon-button pointer-events-none' 
                                                                        : 'icon-button'
                                                                    } 
                                                                    aria-label="add"
                                                                    onClick={increaseQty}
                                                                >
                                                                    
                                                                        <AddIcon className={quantity === product.stock || product.stock <= 1 ? 'disabled' : 'primary'} />                                                                    
                                                                </button> 
                                                            </ToolTip>                           
                                                                                                                        
                                                        </div>  
                                                    </td>
                                                </tr>    
                                             )}
                                            {user && user.role === 'admin' && (
                                                <tr>
                                                    <th>Edit</th>
                                                    <td>
                                                         <Link to={`/admin/product/${product._id}`}>
                                                            <ToolTip title="Update">
                                                                <button className="icon-button">
                                                                    <EditOutlinedIcon aria-label="edit" />
                                                                </button>
                                                            </ToolTip>
                                                        </Link>     
                                                    </td>
                                                </tr>
                                            )}                                                                                                      
                                        </tbody>     
                                    </table>  

                                </div>                            

                            </div>

                            {product.stock > 0 && (
                                <div className="d-flex" style={{ marginTop: 40 }}>

                                <BuyNow 
                                    slug={product.slug} 
                                    quantity={quantity} 
                                    label="Add to Cart" 
                                    disabled={product.stock < 1 ? true : false}
                                    query={queryParams.get('isCartOpen')}
                                />
                            </div>
                            )}                           

                            {product.description && (
                                <div style={{ margin: '40px 0' }}>
                                    <RichtextOutput text={product.description} />
                                </div>
                            )} 

                            <div className="d-flex justify-content-center" style={{marginBottom: 40 }}>

                                <ul className="bullet-list">
                                {product.genusList && product.genusList.map((item, index) => (
                                    <li key={index}>{item.name}</li>
                                ))}
                                </ul>

                            </div>   

                            <figure className="text-center">

                                <img 
                                    src="https://res.cloudinary.com/marine-graphics/image/upload/v1687030315/fishslates/logo/118-x-43_ov8zkg.png" 
                                    alt="Shark graphic" 
                                    style={{ width: 118, margin: '40px 0' }}
                                />     

                            </figure>                   

                        </article>

                    </div>

                </div>   

            </div>        

            {product.images && product.images[currentIndex] && product.images[currentIndex].url && (
                <div style={{ background: 'var(--card-background)' }}>
                    <Parallax url={cloudinaryURL(product.images[currentIndex].url)} />
                </div>
            )} 

            <div className="container contained">

                <div className="wrapper">  

                    <h2 style={{ color: '#999' }}>See what others have said ...</h2>

                    {user ? 
                        <div className="d-flex align-items-center" style={{ marginBottom: 20 }}>
                            <button 
                                className="icon-button"
                                aria-label="Add review"
                                onClick={() => {toggleModal()}
                            }>
                                <EditOutlinedIcon />
                            </button>
                            &nbsp;
                            <small>
                                {product.reviews && product.reviews.length > 0 ? 'Post review' : 'Be the first to post a review' }
                            </small>  
                        </div>      
                    : 
                        <Link 
                            className="d-flex align-items-center"
                            to={`/login?redirect=/product/${slug}`}
                            style={{ marginBottom: '20px' }}
                        >
                            <button className="icon-button" aria-label="Login">
                                <LoginIcon />
                            </button>    
                            Login to post a review                                                      
                        </Link>
                    } 

                    {product.reviews && product.reviews.length > 0 && (  
                                                  
                        <ListReviews 
                            reviews={product.reviews} 
                            user={user} 
                            toggleModal={toggleModal}
                            deleteReviewHandler={deleteReviewHandler}
                        />   
                    
                    )}

                </div>

            </div>

            <Parallax url={cloudinaryURL('https://res.cloudinary.com/marine-graphics/image/upload/v1687158101/fishslates/parallax/underwater-scene-4_fbetvi.jpg')} />
            
            {products && filteredProductsCount > 0 && (                              
                <div className="container contained">
                    <div className="wrapper">  
                        <h2 style={{ color: '#999' }}>Similar products</h2> 
                        <div className="showroom">  
                            {products.map(product => (
                                <Product key={product._id} product={product} />                                    
                            ))}  
                        </div>
                    </div>
                </div> 
            )}                      

            <Parallax url={cloudinaryURL('https://res.cloudinary.com/marine-graphics/image/upload/v1687158101/fishslates/parallax/underwater-scene-3_yg9ccs.jpg')} />                               
                     
            <Modal
                isModalVisible={isModalVisible} 
                onBackdropClick={toggleModal}   
                content={
                    <Review rating={rating} comment={comment} />
                }
            /> 

            <Modal
                isModalVisible={isLightboxOpen}
                onBackdropClick={handleLightbox}
                fullWidth={true}
                translateStart="translateY(0)"
                translateEnd="translateY(100%)"
                content={
                <Lightbox
                    images={product.images}
                    isLightboxOpen={isLightboxOpen}
                    setIsLightboxOpen={setIsLightboxOpen}
                    currentIndex={currentIndex}
                    setCurrentIndex={setCurrentIndex}
                    altTag={product.name}
                />
                }
            />
            </>
        )}
        </>  
    )

}

export default ProductDetails
